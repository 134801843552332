<template>
  <div>

    <div class="grid grid-cols-1 md:grid-cols-2 mb-6">
      <div class="col-span-1 flex items-center">
        <h2 class="text-2xl font-bold">Trips</h2>
      </div>
      <div class="col-span-1">
        <search-form v-model="bills.query" @submit="event => $refs.table.loadAjaxData()" />
      </div>
    </div>

    <datatable
      :data="bills.data"
      :index="true"
      :reverse-index="true"
      :ajax="true"
      :ajaxPagination="true"
      :onClick="showDetails"
      :url="bills.url"
      :columns="bills.columns"
      :query="bills.query"
      ref="table"
    />

    <modal
      className="w-11/12 sm:w-443px flex flex-col"
      ref="tripsDetailsModal"
    >
      <div class="sm:-mx-6">
        <h4 class="text-13 text-markeplace-blue -mt-6">Trips Details</h4>
<!--        <div class="flex justify-center mt-6">-->
<!--          <logo-round></logo-round>-->
<!--        </div>-->
        <div class="mt-4 flex flex-col text-marketplace-blue text-center">
          <span class="text-12 px-6 sm:px-12 leading-relaxed font-normal">
<!--            {{ statusProgress() }}-->
          </span>
          <h2 class="font-extrabold text-32 mt-4">
<!--            {{ orderAmount }}-->
          </h2>
        </div>

        <div class="border-1 bg-marketplace-blue bg-opacity-85 mt-8"></div>
        <div v-for="traveller in travellersDetails" :key="traveller.id">
          <h4 class="text-13 text-markeplace-blue mt-5">Travellers details</h4>
          <div class="order mt-2">
            <h1 class="order-text">First Name</h1>
            <h1 class="order-text">
              {{ traveller.first_name }}
            </h1>
          </div>

          <div class="order">
            <h1 class="order-text">Last Name</h1>
            <h1 class="order-text">
              {{ traveller.last_name }}
            </h1>
          </div>

          <div class="order">
            <h1 class="order-text">Date Of Birth</h1>
            <h1 class="order-text">
              {{ traveller.dob }}
            </h1>
          </div>

          <div class="order">
            <h1 class="order-text">E ticket Id</h1>
            <h1 class="order-text">
              {{ traveller.e_ticket_number }}
            </h1>
          </div>
          <div class="order">
            <h1 class="order-text">Reference Id</h1>
            <h1 class="order-text">
              {{ traveller.traveller_reference_id }}
            </h1>
          </div>
          <div class="order">
            <h1 class="order-text">Passenger Type Code</h1>
            <h1 class="order-text">
              {{ traveller.passenger_type_code }}
            </h1>
          </div>
        </div>
      </div>
    </modal>

  </div>
</template>

<script>
  export default {
    data() {
      return {
        bills: this.$options.resource(null, {
          url: `${this.$baseurl}/admin/expense/trips`,
          query: '',
          columns: [
            {
              th: 'User',
              name: 'user',
              render: (trips, user) => `${user?.name} ${user?.last_name || ''}`
            },
            {
              th: 'User Id',
              name: 'user_id',
              render: (trips) => trips.user.id
            },
            {
              th: 'type',
              name: 'type',
              render: (trips) => trips.type.split('-')[1]
            },
            // {
            //   th: 'Amount',
            //   name: 'amount',
            // },
            {
              th: 'Payment Method',
              name: 'wallet_type',
            },
            {
              th: 'Status',
              name: 'status',
              render: (trips, status) => {
                switch (status) {
                  case 'success':
                    return '<div class="badge badge-green-soft-outline">Success</div>'
                  case 'failed':
                    return '<div class="badge badge-red-soft-outline">Failed</div>'
                  default:
                    return '<div class="badge badge-yellow-soft-outline">Pending</div>'
                }
              }
            },

            {
              th: 'Airline',
              name: 'operating_airline_name',
              render: (trips) => {
                if (!trips.operating_airline_name) {
                    return "---";
                  }
                  return trips.operating_airline_name
              }
            },
            {
              th: 'No Of Tickets',
              name: 'trip_travellers',
              render: (trips) => {
                if (!trips.trip_travellers) {
                    return "---";
                  }
                  return trips.trip_travellers?.length
              }
            },
            {
              th: 'Trip Route',
              name: 'payment_method',
              render: (trips) => {
                if (!trips.departure_airport_code) {
                  return "---";
                }

                return `${trips.departure_airport_code} -> ${trips.arrival_airport_code}`;
              }
            },

            {
              th: 'Cabin Class',
              name: 'flight_class',
              render: (trips) => {
                if (!trips.flight_class) {
                    return "---";
                  }
                  return trips.flight_class
              }
            },
            {
              th: 'Take Off/Touch Down Location',
              name: 'payment_method',
              render: (trips) => {
                if (!trips.departure_airport_name) {
                  return "---";
                }

                return `${trips.departure_airport_name} -> ${trips.arrival_airport_name}`;
              }
            },
            {
              th: 'Ticket Fee',
              name: 'amount',
                render: (trips) => {
                return `<span className="text-blue-300">₦ ${this.$options.filters.currency(
                  trips.amount || 0
                )}</span>`;
              }
            },
            {
              th: 'Amount',
              name: 'amount',
                render: (trips) => {
                return `<span className="text-blue-300">₦ ${this.$options.filters.currency(
                  trips.amount || 0
                )}</span>`;
              }
            },
            {
              th: 'Date',
              name: 'created_at',
              render: (trips, date) => this.$options.filters.dateFormat(date, 'D, dd M Y, h:ia')
            },
          ],
        }),
        travellersDetails: [],
      }
    },
    methods: {
      showDetails(trips) {
        // this.transactionDetails = { ...order };
        this.travellersDetails = trips?.trips_travellers;
        this.$refs.tripsDetailsModal.open();
      },
    }
  }
</script>
<style lang="scss" scoped>
.order {
  @apply flex;
  @apply justify-between;
  @apply items-center;
  @apply text-marketplace-blue;
  @apply mt-8;
  &-text {
    @apply text-11;
    @apply font-normal;
  }
}
</style>
